import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import dynamic from 'next/dynamic';
import {NextUIProvider} from '@nextui-org/react'
import {ThemeProvider as NextThemesProvider} from "next-themes";
import {useRouter} from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createContext, useContext, useState } from 'react';
import { NextPageContext } from 'next';
import { IUser } from '../db/models';
// import "react-toastify/dist/ReactToastify.minimal.css";

export type PageBase = {
  user: IUser | null | undefined
};

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return <>
    <Head>
      <title>العتبات للسفر و سیاحیة</title>
      <meta charSet="utf8" />
    </Head>
    <NextUIProvider navigate={router.push}>
      <ToastContainer className='rounded-lg' />
      <NextThemesProvider attribute="class" defaultTheme="light">
        <Component {...pageProps} />
      </NextThemesProvider>
    </NextUIProvider>
  </>
}

export default dynamic(() => Promise.resolve(App), { ssr: false });
